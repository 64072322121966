import './font.css';

import { Global, css } from '@emotion/react';
import React, { FunctionComponent } from 'react';

export const globalStyles = {
  mobile: '576px',
};

export const defaultStyle = css`
  :root {
    --color-primary: #e9e9e9;
    --color-secondary: #4c4c4c;
    --color-secondary-1: #878787;
    --color-secondary-2: #969696;
    --color-accent: #222222;
    --color-accent-1: #383838;
    --bg-primary: #fefefe;
    --bg-secondary: #f6f8fa;

    --width-primary: 768px;
    --width-center: 0 auto;
    --height-primary: 90px;
    --height-secondary: 80px;
    --inner-primary: 32px;
    --inner-secondary: 24px;
    --inner-secondary-1: 20px;
    --inner-secondary-2: 16px;
    --inner-secondary-3: 12px;

    --pad-width: ${globalStyles.mobile};
    --pad-inner: 16px;
    --pad-inner-1: 8px;
    --pad-inner-2: 4px;

    --border-primary: 1px;
    --rounded-small: 8px;
    --rounded-normal: 12px;
    --rounded-xlarge: 40px;
    --rounded-xxlarge: 50px;

    --color-button: #fefefe;
    --color-icon: var(--color-secondary);
    --color-link: #bbeffd;
    --color-link-bg: #bbeffd4c;
    --color-code: #ffe56433;

    --text-title: calc(22 / 16 * 1rem);
    --text-title-pad: calc(18 / 16 * 1rem);
    --text-post: calc(16 / 16 * 1rem);
    --text-post-pad: calc(14 / 16 * 1rem);
    --text-pad: calc(14 / 16 * 1rem);
    --text-secondary: 12px;
    --text-category: calc(10 / 16 * 1rem);
    --text-footer: calc(var(--text-secondary) * 0.8);

    --img-resizing: 16 / 12;
    --img-inner-top: calc(12 / 16 * 100 * 1%);

    --header-inner: 24px;
    --header-icon: calc(48 / 16 * 1rem);
    --header-pad-icon: calc(36 / 16 * 1rem);

    --category-gap: 3px 8px;
    --about-gap: var(--pad-inner);
  }

  /* css reset */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* pointer-events: none; */

    ::-webkit-scrollbar {
      display: none;
    }

    scroll-behavior: smooth;
  }

  #___gatsby {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: 'Pretendard', -apple-system;
    background-color: var(--bg-primary);
    color: var(--color-accent-1);
    font-size: 16px;
  }

  a,
  a:hover {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }
`;

const GlobalStyle: FunctionComponent = function () {
  return <Global styles={defaultStyle} />;
};

export default GlobalStyle;
