import 'intersection-observer';

import GlobalStyle from './src/styles/GlobalStyle';
import React from 'react';

require('prism-themes/themes/prism-ghcolors.css');
require('prismjs/plugins/command-line/prism-command-line.css');

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <GlobalStyle />
    </>
  );
};
