import { github } from '@code-surfer/themes';
import merge from 'deepmerge';
import wavesTheme from 'gatsby-theme-waves/src/gatsby-plugin-theme-ui/index';
import waves from './waves';

export default merge.all([
  {
    styles: { waves },
  },
  github,
  wavesTheme,
  {
    styles: { waves },
  },
]);
